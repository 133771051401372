/**
 * This is a file containing constants for all of the screen names. In most cases, we should use the routes for
 * navigation. But there are situations where we may need to access screen names directly.
 */
export default {
    HOME: 'Home',
    LOADING: 'Loading',
    REPORT: 'Report',
    NOT_FOUND: 'not-found',
    TRANSITION_FROM_OLD_DOT: 'TransitionFromOldDot',
};
